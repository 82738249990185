<template>
    <div class="pr32">
        <div class="el-tabs el-tabs--top">
            <div class="el-tabs__header is-top">
                <div class="el-tabs__nav-wrap is-top">
                    <div class="el-tabs__nav-scroll">
                        <div class="el-tabs__nav is-top" style="transform: translateX(0px);">
                            <div class="el-tabs__active-bar is-top" style="width: 70px; transform: translateX(0px);" v-if="type === '1'"></div>
                            <div class="el-tabs__active-bar is-top" style="width: 72px; transform: translateX(110px);" v-if="type === '2'"></div>
                            <div class="el-tabs__active-bar is-top" style="width: 56px; transform: translateX(222px);" v-if="type === '3'"></div>
                            <div class="el-tabs__item is-top" :class="{'is-active': type === '1'}" @click="actionChange('1')">店铺背景图</div>
                            <div class="el-tabs__item is-top" :class="{'is-active': type === '2'}" @click="actionChange('2')">店铺banner</div>
                            <div class="el-tabs__item is-top" :class="{'is-active': type === '3'}" @click="actionChange('3')">楼层管理</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "storeMenu",
    data() {
      return {}
    },
    props: {
      type: {
        type: String,
        default: '', // 1-店铺背景图， 2-店铺banner， 3-楼层管理
      }
    },
    methods: {
      actionChange(e) {
        if (this.type === e) {
          return false;
        }

        switch (e) {
          case '1':
            this.$router.replace('/admin/store/backend');
            break;
          case '2':
            this.$router.replace('/admin/store/banner');
            break;
          case '3':
            this.$router.replace('/admin/store/floor');
            break;
        }
      }
    }
  }
</script>
